<template>
  <div>
    <p v-show="errorTableData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo los datos
    </p>

    <p>
      Link público para inscribirse: <a href="/inscripciones">Inscripciones</a>
    </p>

    <v-card v-if="!errorTableData">
      <v-card-title>
        <NuevaActividad></NuevaActividad>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :search="search"
        :loadingTableData="loadingTableData"
        loadingTableData-text="Cargando..."
        @click:row="rowClick"
      >
        <template v-slot:item.fecha="{ item }">
          {{ new Date(item.fecha).toLocaleDateString() }}
        </template>

        <template v-slot:item.precio="{ item }"> {{ item.precio }} € </template>

        <template v-slot:item.visible="{ item }">
          <v-icon v-if="item.visible">mdi-check-box-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import NuevaActividad from '../components/Actividades/NuevaActividad';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: false,
      loadingTableData: true,
      search: '',
      tableHeaders: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Precio', value: 'precio' },
        { text: 'Inscripciones', value: 'inscripciones' },
        { text: 'Visible', value: 'visible' },
      ],
    };
  },
  methods: {
    async getTableData() {
      try {
        this.loadingTableData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/actividades');

        this.tableData = res.data;

        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;

        this.errorTableData = true;
      }
    },
    async rowClick(row) {
      this.$router.push({ name: 'Actividad', params: { id: row._id } });
    },
  },
  components: {
    NuevaActividad,
  },
  mounted() {
    this.getTableData();
    this.$emit('usePanel', true);
  },
};
</script>

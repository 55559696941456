<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-4" dark color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>
          mdi-plus
        </v-icon>
        Nueva
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nueva actividad
      </v-card-title>
      <v-card-text>
        <v-menu
          ref="menu"
          v-model="fecha"
          :close-on-content-click="false"
          :return-value.sync="nuevoItem.fecha"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="nuevoItem.fecha"
              label="Fecha"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="nuevoItem.fecha" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fecha = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="nuevoItem.fecha = ''">
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(nuevoItem.fecha)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field label="Nombre" v-model="nuevoItem.nombre"></v-text-field>

        <v-text-field
          label="Plazas"
          v-model="nuevoItem.plazas"
          hint="Usa 0 para plazas ilimitadas"
        ></v-text-field>

        <v-text-field label="Precio" v-model="nuevoItem.precio"></v-text-field>

        <v-textarea
          label="Descripción"
          v-model="nuevoItem.descripcion"
        ></v-textarea>

        <v-checkbox v-model="nuevoItem.visible" label="Visible"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevaActividad',
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fecha: false,
      nuevoItem: {
        fecha: '',
        nombre: '',
        plazas: 0,
        precio: 0,
        descripcion: '',
        visible: false,
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.loading = true;

        const res = await axios.post(process.env.VUE_APP_API_URL + '/actividades/nuevo', this.nuevoItem);

        this.loading = false;

        this.toggleDialog = false;

        this.$router.push({
          name: 'Actividad',
          params: { id: res.data._id },
        });
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
